import Loadable from "../../../components/Loadable";

const NilaiEkskul = Loadable(() => import("./NilaiEkstrakurikuler/NilaiEkskul"));
const DetilNilaiEkskul = Loadable(() => import("./NilaiEkstrakurikuler/DetilNilaiEkskul"));
const DaftarEkskul = Loadable(() => import("./DaftarEkstrakurikuler/DaftarEkskul"));
const DetilDaftarEkskul = Loadable(() => import("./DaftarEkstrakurikuler/DetilDaftarEkskul"));
const TambahDaftarEkskul = Loadable(() => import("./DaftarEkstrakurikuler/TambahDaftarEkskul"));

export default {
	NilaiEkskul, DetilNilaiEkskul,
	DaftarEkskul, DetilDaftarEkskul, TambahDaftarEkskul
};