import Loadable from "../../../components/Loadable";

const DetilKBM = Loadable(() => import("./DetilKBM"));
const DetilDaftarKelas = Loadable(() => import("./DetilDaftarKelas"));
const DaftarKBM = Loadable(() => import("./DaftarKBM"));
const DaftarKelas = Loadable(() => import("./DaftarKelas"));
const KelasBaru = Loadable(() => import("./KelasBaru"));
const DaftarKelompokHafalan = Loadable(() => import("./DaftarKelompokHafalan"));
const DetilKelompokHafalan = Loadable(() => import("./DetilKelompokHafalan"));

const DaftarKelompokP5 = Loadable(() => import("./KelompokP5/DaftarKelompokP5"));
const DetilKelompokP5 = Loadable(() => import("./KelompokP5/DetilKelompokP5"));
const KelasKelompokP5 = Loadable(() => import("./KelompokP5/KelasKelompokP5"));
const TambahKelompokP5 = Loadable(() => import("./KelompokP5/TambahKelompokP5"));

export default {
	DetilKelompokHafalan,
	DaftarKelompokHafalan,
	DaftarKBM, KelasBaru , DaftarKelas ,DetilKBM, DetilDaftarKelas,

	DaftarKelompokP5, DetilKelompokP5, KelasKelompokP5, TambahKelompokP5
};