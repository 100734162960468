import _ from 'lodash'
import styled from 'styled-components'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import {Image, Header, Divider} from 'semantic-ui-react'

import logo_sekolah from '../../assets/logo_sekolah_mini.png'
import {CONTACT_IMPULS} from '../../helpers/constant'

const Container = styled.div`
	width:100%;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-wrap:wrap;
`

class UnderMaintenance extends Component {

	render() {
	  const namaSekolah =  _.get(this.props, 'nama', 'Nama SMA')
		return (
			<Container>
				<div style={{textAlign:'center'}}>
					<div style={{display:'flex', justifyContent:'center'}}>
						<Image src={logo_sekolah} size="tiny"/>
					</div>
					<Header as="h2" style={{fontWeight:450, marginTop:'0.5em' ,marginBottom:'0.5em'}} color="theme">Selamat datang di</Header>
					<Header as="h2" style={{ marginTop: 0, marginBottom: 0 }} color="theme">Sistem Manajemen Sekolah</Header>
					<Header as="h2" style={{marginTop:0}} color="theme">{namaSekolah}</Header>
					<Divider style={{marginTop:'3em',marginBottom:'3em'}}></Divider>
					<p style={{fontSize:'18px', marginBottom:'4em'}}>Mohon maaf saat ini kami sedang melakukan pemeliharaan/ maintenance</p>
					<p>Untuk mengetahui status terbaru kami, silakan menghubungi :</p>
					<p>Handphone/Whatsapp : <a href={`https://wa.me/${CONTACT_IMPULS.replace('081', '6281')}`} target="blank">{CONTACT_IMPULS}</a></p>
				</div>
			</Container>
		)
	}
}

UnderMaintenance.propTypes = {
	nama: PropTypes.string.isRequired,
}

export default UnderMaintenance
