import Loadable from '../../../components/Loadable';

const PengaturanProfil  = Loadable(() => import("./PengaturanProfil/PengaturanProfil"));

const DaftarPengaturanProyek  = Loadable(() => import("./Proyek/DaftarPengaturanProyek"));
const DetilPengaturanProyek  = Loadable(() => import("./Proyek/DetilPengaturanProyek"));
const TambahPengaturanProyek  = Loadable(() => import("./Proyek/TambahPengaturanProyek"));

const DaftarKelasNilaiP5  = Loadable(() => import("./DaftarNilaiP5/DaftarKelasNilaiP5"));
const DaftarProyekNilaiP5  = Loadable(() => import("./DaftarNilaiP5/DaftarProyekNilaiP5"));
const DetilInputNilaiP5  = Loadable(() => import("./DaftarNilaiP5/DetilInputNilaiP5"));

const DaftarRaporPenilaianP5  = Loadable(() => import("./RaporPenilaianP5/DaftarRaporPenilaianP5"));
const DetilRaporPenilaianP5  = Loadable(() => import("./RaporPenilaianP5/DetilRaporPenilaianP5"));


export default {
	PengaturanProfil,

	DaftarPengaturanProyek,
	TambahPengaturanProyek,
	DetilPengaturanProyek,

	DaftarKelasNilaiP5,
	DaftarProyekNilaiP5,
	DetilInputNilaiP5,
	
	DaftarRaporPenilaianP5,
	DetilRaporPenilaianP5
};