import Loadable from "../../../components/Loadable";

const DaftarPeserta = Loadable(() => import("./DaftarPeserta/DaftarPeserta"));
const DetilDaftarPeserta = Loadable(() => import("./DaftarPeserta/DetilDaftarPeserta"));
const InputNilai = Loadable(() => import("./InputNilai/InputNilai"));
const PengaturanPPDB = Loadable(() => import("./PengaturanPPDB/PengaturanPPDB"));
const HasilPPDB = Loadable(() => import("./HasilPPDB/HasilPPDB"));
const RekapNilai = Loadable(() => import("./RekapNilai/RekapNilai"));
const InputWawancara = Loadable(() => import("./Wawancara/InputWawancara"));
const DetilWawancara = Loadable(() => import("./Wawancara/DetilWawancara"));
const DaftarCalonPeserta = Loadable(() => import("./DaftarCalonPeserta/DaftarCalonPeserta"));
const DetilCalonPeserta = Loadable(() => import("./DaftarCalonPeserta/DetilCalonPeserta"));

export default {
	DaftarCalonPeserta,
	DetilCalonPeserta,
	DetilWawancara,
	InputWawancara,
	RekapNilai,
	HasilPPDB,
	DetilDaftarPeserta,
	PengaturanPPDB,
	InputNilai,
	DaftarPeserta,
}