import React from 'react'
import styled from "styled-components"
import { Icon, Button, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const FlexContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column
	justify-content: center;
	align-content: center;
	align-items: center;
	padding-bottom: 1.5em;
`

const Error403 = () => {
	return (
			<FlexContainer>
			<div style={{margin: ".5em"}}><Icon name="warning sign" color="yellow" size="huge"/></div>
			<div style={{margin: ".5em"}}><Header as="h1">Eror 403</Header></div>
			<div style={{margin: ".5em"}}>Maaf, Anda tidak memiliki hak akses ke halaman ini</div>
			<div>Klik, <span>{<Link to="/"><Button primary size="tiny" icon="arrow left"/></Link>}</span> untuk kembali ke halaman utama.</div>
		</FlexContainer>
	)
}

export default Error403;
