import _ from 'lodash';
import React from 'react'
import { Button, Card, Dimmer, Header, Icon, Loader } from 'semantic-ui-react'
import { useMuridPrintRapor, useMuridShowHakAkses } from '../../api/murid';
import { Error423 } from '../../collection/Error/423';

const UnduhRapor = (props) => {
  const id = _.get(props, 'muridId');
  const type = _.get(props, 'type', 'uas');
  const kode = _.get(props, 'kode');
  const tahun = _.get(props, 'tahun');
  const semester = _.get(props, 'semester');
  const label = _.get(props, 'label', 'Rapor PAS');
  const tahunAjaranId = _.get(props, 'tahunAjaranId');

  const { mutate, isPending: loading } = useMuridPrintRapor(id);

  const query = {
    type,
    tahun_ajaran_id: tahunAjaranId,
    filename: `Rapor ${type} - Kelas ${kode} - ${tahun} Smt ${semester}.pdf`,
  };

  return <Button 
    size='small' basic 
    disabled={loading} 
    loading={loading} 
    onClick={() => mutate(query)}
  >
    <Icon name="print"/>{label}
  </Button>
}

export const CardRaporMurid = (props) => {
  const kode = _.get(props, 'kode');
  const tahun = _.get(props, 'tahun');
  const semester = _.get(props, 'semester');

  const muridId = _.get(props, 'muridId');
  const tahunAjaranId = _.get(props, 'tahun_ajaran_id');

  const { data, isFetching } = useMuridShowHakAkses(muridId, { tahun_ajaran_id: tahunAjaranId });

  const locked = _.get(data, 'result.kunci_rapor') === true;

  const btnProps = {
    type: "uas",
    muridId, kode, tahun, semester, tahunAjaranId,
  }

  return <div style={{ height: "100%" }}>
    <Dimmer active={isFetching}><Loader/></Dimmer>
    <Card fluid style={{ minHeight: '11em', height: "100%" }}>
    <Card.Content><Header as="h5" color="blue">
      Kelas {kode}
      <Header.Subheader>
        Tahun Ajaran {tahun} Semester {semester}
      </Header.Subheader>
    </Header></Card.Content>

    {locked && <Card.Content>
      {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}> */}
      <Error423 size="small"/>
    </Card.Content>}    

    {!locked && <Card.Content>
      <div style={{ paddingBottom: "1.4em" }}>
        <Header as="h5" style={{ margin: "0", marginBottom: ".5em" }}>Rapor Akademik</Header>
        <UnduhRapor {...btnProps} type="UTS" label="Rapor PTS"/>
        <UnduhRapor {...btnProps} type="UAS" label="Rapor PAS"/>
      </div>

      <div style={{ paddingBottom: "1.4em" }}>
        <Header as="h5" style={{ margin: "0", marginBottom: ".5em" }}>Rapor P5</Header>
        <UnduhRapor {...btnProps} type="P5" label="Rapor P5"/>
      </div>

      <div style={{ paddingBottom: "1.4em" }}>
        <Header as="h5" style={{ margin: "0", marginBottom: ".5em" }}>Rapor Tahfidz</Header>
        <UnduhRapor {...btnProps} type="TAHFIDZ" label="Rapor Tahfidz"/>
      </div>
    </Card.Content>}
    </Card>
  </div>
}
