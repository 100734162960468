import Loadable from "../../../components/Loadable";

const DetilTatib = Loadable(() => import('./Detil'));
const MasukanTatib = Loadable(() => import('./Masukan'));
const RekapitulasiTatib = Loadable(() => import('./Rekapitulasi'));

export default {
	DetilTatib,
	MasukanTatib,
	RekapitulasiTatib
};