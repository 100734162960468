import _ from 'lodash';
import { Link } from "react-router-dom"; 
import styled from 'styled-components';
import { Header, Icon, Label } from 'semantic-ui-react';
import React, { memo, useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../../context';

const theme_color = "themeColor"

const MoleculeDropdown = props => {
    const { state } = useContext(UserContext);
    const [ active, setActive ] = useState('');

    // console.log(props.selected, props.id)  
    const pathname = window.location.href
    
    const handleActive = id => setActive(id);
    useEffect(() => {
        !_.isEqual(props.selected, props.id) && handleActive('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected]);

    let propsMenu = props.menu

    const search = _.get(props, "search", "") || "";
    const child = _.get(props, "child", []).filter(item => _.includes(_.lowerCase(item.menu), search))
    const show = search.length !== 0 
      ? child.length !== 0 || _.includes(_.lowerCase(propsMenu), search)
      : true
    const selected = (_.isEmpty(child) ? _.includes(pathname, props.to) : !_.chain(child).filter(o => _.includes(pathname, o.to)).isEmpty().value()) || _.isEqual(props.selected, props.id) || 
    ((search.length !== 0) && (child.length !== 0 || _.includes(_.lowerCase(propsMenu), search) ? true : false)) 
      ? propsMenu 
      : 
      false
    return (<Style 
      selected={selected} 
      nav={state.navigation.active ? true : (state.navigation.hover)}
      show={show}
    >
      {!_.isEmpty(child) 
        ? <div
            className='dropdown-header'
            onClick={() => {
              props.handleSelected(props.id); 
              props.resetSearch(); 
              return !_.isEmpty(child) ? null : props.history.push(props.to)
            }}
          >
            <div>
              {props.logo && props.logo !== 'imp imp-profil' && (
                <>
                  <Icon size='big' name={props.logo} color={selected ? theme_color : 'grey'} />
                  {props.notifiationTotal && <StyledLabel size='tiny' circular color='red'>{props.notifiationTotal}</StyledLabel>}
                </>
              )}
              {props.logo === 'imp imp-profil' && (
                <Icon size='big' name={props.logo} color={selected ? theme_color : 'grey'} />
              )}
            </div>
            <div className='dropdown-heading'>
              <div><Header color={selected ? 'theme' : 'grey'} size="tiny">{propsMenu}</Header></div>
              <div>{props.child.length !== 0 ? <Icon  color={selected ? theme_color : 'black'} name={selected ? 'caret down' : 'caret left'} /> : ''}</div>
            </div> 
          </div>
        : <Link style={{textDecoration: "none"}} to={props.to}>
          <div
            className='dropdown-header'
            onClick={() => {
              props.handleSelected(props.id); 
              props.resetSearch(); 
              return !_.isEmpty(child) ? null : props.history.push(props.to)
            }}
          >
            <div>
              {props.logo && props.logo !== 'imp imp-profil' && (
                <>
                  <Icon size='big' name={props.logo} color={selected ? theme_color : 'grey'} />
                  {props.notifiationTotal && <StyledLabel size='tiny' circular color='red'>{props.notifiationTotal}</StyledLabel>}
                </>
              )}
              {props.logo === 'imp imp-profil' && (
                <Icon size='big' name={props.logo} color={selected ? theme_color : 'grey'} />
              )}
            </div>
            <div className='dropdown-heading'>
              <div><Header color={selected ? 'theme' : 'grey'} size="tiny">{propsMenu}</Header></div>
              <div>{props.child.length !== 0 ? <Icon  color={selected ? theme_color : 'black'} name={selected ? 'caret down' : 'caret left'} /> : ''}</div>
            </div> 
          </div>
        </Link>
      }
      {selected &&
        <div className='dropdown-list'>
          {!_.isEmpty(child) && child.map(item => {
            let onClick = () => { props.handleSelected(props.id); handleActive(item.id); props.resetSearch(); props.history.push(item.to)}
            if(_.isFunction(_.get(item, "onClick"))){
              onClick = _.get(item, "onClick")
            }
            return <div className='dropdown-header'>
              <div></div>
              <div onClick={onClick}>
                <Link style={{textDecoration: "none"}} to={_.get(item, "to", "")}>
                  <Header
                    color={active === item.id || _.includes(pathname, item.to) ? 'theme' : 'grey'}
                    size="tiny"
                  >
                    {item.menu}
                  </Header>
                </Link>
              </div>
              <div></div>
            </div>
          })}
        </div>
      }
    </Style>);
};

const Style = styled.div`
    background-color: ${oo => oo.selected ? 'white' : 'transparent'};
    border-radius: 4px;
    min-height: 50px;
    padding: 10px 7px;
    display: ${oo => oo.show ? 'default' : 'none'};

    .dropdown-header {
        border-radius: 4px;
        cursor: pointer;
        display: grid;
        grid-template-columns: ${oo => oo.nav ? '30px auto' : '1fr'};
        gap: 20px;
        position: relative;

        .ui.image {
            width: 26px;
            height: 26px;
            margin: 2px auto 0;
        }
    }

    .dropdown-heading {
        display: ${oo => oo.nav ? 'flex' : 'none'};
        justify-content: space-between;
        align-self: center;
        align-items: center;
    }

    .dropdown-list {
        display: ${oo => oo.nav ? 'default' : 'none'};

        .dropdown-header {
            gap: 0;
        }

        .ui.header {
            cursor: pointer;
            margin-left: 22px;
            margin-top: 18px;
            
            &:hover {
                color: black !important;
            }
        }
    }

    .ui.header {
        user-select: none;
        font-weight: 600;
        margin-top: 2px;
        margin-left: 2px;
    }

    &:hover {
        background-color: ${oo => oo.selected ? 'white' : '#EAECEF'};;
    }

    @media (max-width: 480px) {
        display: default;

        .dropdown-header {
            grid-template-columns: 30px auto;
        }

        .dropdown-heading {
            display: flex;
        }

        .dropdown-list {
            display: block;
        }
    }
`;

const StyledLabel = styled(Label)`
    position: absolute;
    left: 16px;
    top: -4px;
`;

export default memo(MoleculeDropdown);