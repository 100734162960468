import Loadable from "../../../components/Loadable";

const CatatanKonseling = Loadable(() => import("./CatatanKonseling"));
const Peminatan = Loadable(() => import("./Peminatan"));
const DetilKonselingMurid = Loadable(() => import("./DetilKonselingMurid"));
const DetilPeminatan = Loadable(() => import("./DetilPeminatan"));
const RekapPeminatan = Loadable(() => import("./RekapPeminatan"));
const DetilRekapPeminatan = Loadable(() => import("./DetilRekapPeminatan"));
const PengaturanPerguruanTinggi = Loadable(() => import("./PengaturanPerguruanTinggi"));

export default {
	PengaturanPerguruanTinggi,
	DetilRekapPeminatan,
	RekapPeminatan,
	DetilPeminatan,
	DetilKonselingMurid,
	Peminatan,
	CatatanKonseling,
};